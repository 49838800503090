import React, {useEffect, useRef, useState} from 'react';
import ReactHlsPlayer from "react-hls-player";
import {useSearchParams} from "react-router-dom";
import {ReactFlvPlayer} from 'self-flv-player'
import ReactPlayer from "react-player";

const STREAM_SERVER = window._env_.STREAM_SERVER


const View = () => {
    const [params] = useSearchParams();
    const url = params.get("url");
    const referer = params.get("referer");
    const [src, setSrc] = useState("")


    useEffect(() => {
        setSrc(url)
    }, []);
    const playerRef = useRef();

    const handleTogglePictureInPicture = () => {
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
        } else {
            playerRef.current.requestPictureInPicture();
        }
    };
    return (
        <div style={{height:'100vh'}}>
                <ReactPlayer
                    playerRef={playerRef}
                    url={`${STREAM_SERVER}/proxy?url=${src}${referer ? '&referer=' + referer : ''}`}
                    autoPlay={false}
                    controls={true}

                    onPlay={() => {
                        // playerRef.current.requestFullscreen()
                    }}
                    hlsConfig={{maxBufferLength: 180}}
                    width="100%"
                    height="auto"
                />
            <button onClick={handleTogglePictureInPicture}>
                PIP
            </button>
        </div>
    );
};

export default View;
