import React, {useEffect, useRef, useState} from 'react';
import ReactHlsPlayer from "react-hls-player";
import {useSearchParams} from "react-router-dom";
import {ReactFlvPlayer} from 'self-flv-player'
import copy from 'copy-to-clipboard';
import ReactPlayer from "react-player";

const STREAM_SERVER = window._env_.STREAM_SERVER


const SpecialView = () => {
    const [params] = useSearchParams();
    const url = params.get("url");
    const referer = params.get("referer");
    const [src, setSrc] = useState("")

    useEffect(() => {
        setSrc(url)
    }, []);
    const playerRef = useRef();

    const playerSrc = `${STREAM_SERVER}/${url.endsWith(".m3u8") ? 'proxy-playlist' : 'extract-playlist'}?url=${src}${referer ? '&referer=' + referer : ''}`;
    console.log(playerSrc)
    return (
        <div style={{height: '100%',backgroundColor:'grey'}}>
            <ReactPlayer
                playerRef={playerRef}
                url={playerSrc}
                autoPlay={false}
                controls={true}
                width="100%"
                height="auto"
            />
            {/*<button onClick={() => {*/}
            {/*    copy(playerSrc)*/}
            {/*}}>*/}
            {/*    Copy Player Source*/}
            {/*</button>*/}
        </div>
    );
};

export default SpecialView;
